import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";



import { getDatabase } from "firebase/database";

export const firebaseConfig = {
  apiKey: "AIzaSyAFY6olV4zKBY6lqMb7yz8bCJK6VVUC8ns",
  authDomain: "bibliohome-30803.firebaseapp.com",
  databaseURL: "https://bibliohome-30803-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bibliohome-30803",
  storageBucket: "bibliohome-30803.appspot.com",
  messagingSenderId: "370845796347",
  appId: "1:370845796347:web:5200cf786d2f698adc2d5e",
  measurementId: "G-2MML4Z2HT4"
  };

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getDatabase();
export const storage = getStorage(app);